import Cookies from 'js-cookie';
import { Configuration, RefreshTokenControllerApi } from '../api-client';
// eslint-disable-next-line import/order
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import {
  ACCESS_TOKEN,
  CODE_NODATA,
  LOGOUT_DELETE_COOKIE,
  NEW_CLOUD,
  REFRESH_TOKEN,
  SELECT_STACK,
  SERVICE_MODE_MAINTENANCE,
  SERVICE_MODE_NORMAL,
  CATEGORY_ID,
} from '../Constants';
// eslint-disable-next-line import/no-cycle
import { history } from '../App';
import { HTTPStatusCode } from '../constants/HTTPStatusCode';
import { Url } from '../constants/Url';
// eslint-disable-next-line import/no-cycle
import { BaseAPI } from '../api-client/base';

const environment: string = process.env.REACT_APP_ENVIRONMENT || '';
const retryCounterMap = new Map();
const LIMIT_RETRY_COUNT = 1;

export const onInstantiated = (
  self: BaseAPI,
  configuration: Configuration | undefined,
  basePath: string,
  axios: AxiosInstance
): void => {
  // interceptors.request.use で送信時に引数に入れた関数が動作する
  // 引数で渡ってくるのは axios の設定(送信先や通信方式も持つ今まさに通信を実行しようとしている設定)で、返り値が通信時に実際に使われる axios の設定になる
  axios.interceptors.request.use(
    (config) => {
      const mutableConfig = config;
      const accessToken = Cookies.get(ACCESS_TOKEN);
      const refreshToken = Cookies.get(REFRESH_TOKEN);
      if (accessToken && refreshToken) {
        // localhostのみヘッダー付ける
        mutableConfig.headers = {
          ...(config.headers ?? {}),
          ...(accessToken && refreshToken
            ? {
                accesstoken: accessToken,
                refreshtoken: refreshToken,
                ...(environment === 'local' ? { authorization: accessToken } : {}),
              }
            : {}),
          'x-jroi-secret-key': 'orange',
        };
      }
      mutableConfig.headers = Object.assign({ 'x-jroi-secret-key': 'orange' }, config.headers);
      return mutableConfig;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  let isRetry = false;
  // interceptors.response.use で返信時に引数に入れた関数が動作する
  axios.interceptors.response.use(
    // 第一引数は通信成功時処理。受けた内容をそのまま通過
    (response: AxiosResponse) => {
      // リトライ保持情報削除
      if (response.config.url) {
        retryCounterMap.delete(response.config.url);
      }
      // modeが存在している場合
      if (response !== undefined && response.headers) {
        // サービスモードが通常の場合
        // eslint-disable-next-line eqeqeq
        // if (parseInt(response.headers.mode, 10) === SERVICE_MODE_NORMAL) {
        // 選択式、積上げ式を設定
        if (response.headers.selectstack) {
          Cookies.set(SELECT_STACK, response.headers.selectstack);
        }
        // クラウド:trueか新基盤:falseか
        if (response.headers.newcloud) {
          Cookies.set(NEW_CLOUD, response.headers.newcloud);
        }
        // カテゴリーID
        if (response.headers.categoryid) {
          Cookies.set(CATEGORY_ID, response.headers.categoryid);
        }
        // }
        if (parseInt(response.headers.mode, 10) === SERVICE_MODE_MAINTENANCE) {
          history.push(Url.MAINTENANCE);
        }
        if (response.headers.code === CODE_NODATA) {
          history.push(Url.NO_DATA);
        }
      }
      return response;
    },
    // エラー時
    (error: AxiosError) => {
      // 通信失敗時はリトライさせたい
      const originalRequest = error.config;
      // 認証エラー 401
      if (error.response?.status === HTTPStatusCode.Unauthorized && !isRetry) {
        const retryCounter = (retryCounterMap.get(originalRequest.url) || 0) + 1;
        isRetry = true;
        const refreshToken = Cookies.get(REFRESH_TOKEN);
        // リトライが3回以上
        if (retryCounter > LIMIT_RETRY_COUNT) {
          retryCounterMap.delete(originalRequest.url);
          // エラーの場合ログイン画面に戻る
          LOGOUT_DELETE_COOKIE.forEach((s) => {
            Cookies.remove(s);
          });
          history.push(Url.LOGIN);
        }
        // リフレッシュトークンがある場合
        if (refreshToken != null) {
          const api = new RefreshTokenControllerApi();
          return (
            api
              .refreshToken(refreshToken)
              // eslint-disable-next-line consistent-return
              .then((res) => {
                if (res.status === HTTPStatusCode.OK) {
                  Cookies.set(ACCESS_TOKEN, res.data.accessToken);
                  Cookies.set(REFRESH_TOKEN, res.data.refreshToken);
                  const [axiosHeaders, originalRequestHeaders] = [axios.defaults.headers, originalRequest.headers];
                  // localhostのみヘッダー付ける
                  if (environment === 'local') {
                    if (axiosHeaders) axiosHeaders.common.authorization = res.data.accessToken;
                    if (originalRequestHeaders) originalRequestHeaders.authorization = res.data.accessToken;
                  }
                  if (axiosHeaders) axiosHeaders.common.accesstoken = res.data.accessToken;
                  if (originalRequestHeaders) originalRequestHeaders.accesstoken = res.data.accessToken;
                  // 本来行いたかったAPIリクエストを再実行
                  return axios.request(originalRequest);
                }
              })
              .catch(() => {
                retryCounterMap.delete(originalRequest.url);
                console.log('failure token refresh');
                // エラーの場合ログイン画面に戻る
                LOGOUT_DELETE_COOKIE.forEach((s) => {
                  Cookies.remove(s);
                });
                history.push(Url.LOGIN);
                return Promise.reject(error);
              })
          );
        }
        retryCounterMap.delete(originalRequest.url);
        // エラーの場合ログイン画面に戻る
        LOGOUT_DELETE_COOKIE.forEach((s) => {
          Cookies.remove(s);
        });
        history.push(Url.LOGIN);
        return Promise.reject(error);

        // 422以外はエラーページに遷移
      }

      if (
        error.response?.status !== HTTPStatusCode.UnprocessableEntity &&
        error.response?.status !== HTTPStatusCode.Unauthorized &&
        error.response !== undefined // axiosのオプションでsignalを渡している場合、ここはundefined. 画面遷移は不要
      ) {
        if (error.response.headers && error.response.headers.code === CODE_NODATA) {
          history.push(Url.NO_DATA);
        } else {
          history.push(Url.COMMON_ERROR);
        }
      }
      // エラー終了時にPromiseを返す
      return Promise.reject(error);
    }
  );
};
