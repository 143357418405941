/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PasswordResetTokenFormResponse } from '../domains';
// @ts-ignore
import { PasswordResetTokenOutputResponse } from '../domains';
// @ts-ignore
import { PasswordResetUpdateFormResponse } from '../domains';
// @ts-ignore
import { PasswordResetUpdateOutputResponse } from '../domains';
// @ts-ignore
import { ResultOutputResponse } from '../domains';
// @ts-ignore
import { TobAccountCreateFormResponse } from '../domains';
// @ts-ignore
import { TobAccountCreateOutputResponse } from '../domains';
// @ts-ignore
import { TobAccountInfoOutputResponse } from '../domains';
// @ts-ignore
import { TobAccountListOutputResponse } from '../domains';
// @ts-ignore
import { TobAccountListUpdateFormResponse } from '../domains';
// @ts-ignore
import { TobAccountListUpdateOutputResponse } from '../domains';
// @ts-ignore
import { TobAccountSubListOutputResponse } from '../domains';
// @ts-ignore
import { TobAccountSubRefAddFormResponse } from '../domains';
// @ts-ignore
import { TobAccountSubRefAddOutputResponse } from '../domains';
// @ts-ignore
import { TobAccountSubRefListOutputResponse } from '../domains';
// @ts-ignore
import { TobAccountUpdateFormResponse } from '../domains';
// @ts-ignore
import { TobAccountUpdateOutputResponse } from '../domains';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ToBアカウントパスワード再設定トークンを登録する
         * @summary ToBアカウントパスワード再設定トークン登録
         * @param {PasswordResetTokenFormResponse} passwordResetTokenFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetToken: async (passwordResetTokenFormResponse: PasswordResetTokenFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetTokenFormResponse' is not null or undefined
            assertParamExists('passwordResetToken', 'passwordResetTokenFormResponse', passwordResetTokenFormResponse)
            const localVarPath = `/api/password-reset-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetTokenFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ToBアカウントパスワード再設定トークンを登録する
         * @summary ToBアカウントパスワード再設定
         * @param {PasswordResetUpdateFormResponse} passwordResetUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetUpdate: async (passwordResetUpdateFormResponse: PasswordResetUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetUpdateFormResponse' is not null or undefined
            assertParamExists('passwordResetUpdate', 'passwordResetUpdateFormResponse', passwordResetUpdateFormResponse)
            const localVarPath = `/api/public/password-reset-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウント情報を作成する
         * @summary アカウント情報作成
         * @param {TobAccountCreateFormResponse} tobAccountCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountCreate: async (tobAccountCreateFormResponse: TobAccountCreateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tobAccountCreateFormResponse' is not null or undefined
            assertParamExists('tobAccountCreate', 'tobAccountCreateFormResponse', tobAccountCreateFormResponse)
            const localVarPath = `/api/account-create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tobAccountCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントを削除する
         * @summary アカウント削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tobAccountDelete', 'id', id)
            const localVarPath = `/api/account-delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウント情報を取得する
         * @summary アカウント情報取得
         * @param {string} [accountUpdteId] 
         * @param {string} [accountUpdteClientOrgShopId] 
         * @param {string} [allClient] 
         * @param {string} [accountPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountInfo: async (accountUpdteId?: string, accountUpdteClientOrgShopId?: string, allClient?: string, accountPage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountUpdteId !== undefined) {
                localVarQueryParameter['accountUpdteId'] = accountUpdteId;
            }

            if (accountUpdteClientOrgShopId !== undefined) {
                localVarQueryParameter['accountUpdteClientOrgShopId'] = accountUpdteClientOrgShopId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }

            if (accountPage !== undefined) {
                localVarQueryParameter['accountPage'] = accountPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウント一覧を取得する
         * @summary アカウント一覧
         * @param {string} [allClient] 
         * @param {string} [accountListPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountList: async (allClient?: string, accountListPage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }

            if (accountListPage !== undefined) {
                localVarQueryParameter['accountListPage'] = accountListPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウント情報を更新した後、更新後リストを返す
         * @summary アカウント情報更新
         * @param {TobAccountListUpdateFormResponse} tobAccountListUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountListUpdate: async (tobAccountListUpdateFormResponse: TobAccountListUpdateFormResponse, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tobAccountListUpdateFormResponse' is not null or undefined
            assertParamExists('tobAccountListUpdate', 'tobAccountListUpdateFormResponse', tobAccountListUpdateFormResponse)
            const localVarPath = `/api/account-list-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tobAccountListUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TobAccountクライアント・組織・店舗リストを取得する
         * @summary TobAccountクライアント・組織・店舗リスト取得
         * @param {string} [accountid] 
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountRadioList: async (accountid?: string, id?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tobaccount-radio-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountid !== undefined) {
                localVarQueryParameter['accountid'] = accountid;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * サブ所属リストを取得する
         * @summary サブ所属リスト
         * @param {string} [accountid] 
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountSubListOutput: async (accountid?: string, id?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-sub-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountid !== undefined) {
                localVarQueryParameter['accountid'] = accountid;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントのサブ所属を設定追加
         * @summary アカウントサブ所属追加
         * @param {TobAccountSubRefAddFormResponse} tobAccountSubRefAddFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountSubRefAdd: async (tobAccountSubRefAddFormResponse: TobAccountSubRefAddFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tobAccountSubRefAddFormResponse' is not null or undefined
            assertParamExists('tobAccountSubRefAdd', 'tobAccountSubRefAddFormResponse', tobAccountSubRefAddFormResponse)
            const localVarPath = `/api/account-sub-ref-add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tobAccountSubRefAddFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントのサブ所属を削除
         * @summary アカウントサブ所属削除
         * @param {string} [allClient] 
         * @param {string} [subRefId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountSubRefDel: async (allClient?: string, subRefId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-sub-ref-del`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }

            if (subRefId !== undefined) {
                localVarQueryParameter['subRefId'] = subRefId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントのサブ所属リストを取得する
         * @summary アカウントのサブ所属リスト
         * @param {string} [accountid] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountSubRefList: async (accountid?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-sub-ref-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountid !== undefined) {
                localVarQueryParameter['accountid'] = accountid;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウント情報を更新する
         * @summary アカウント情報更新
         * @param {TobAccountUpdateFormResponse} tobAccountUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountUpdate: async (tobAccountUpdateFormResponse: TobAccountUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tobAccountUpdateFormResponse' is not null or undefined
            assertParamExists('tobAccountUpdate', 'tobAccountUpdateFormResponse', tobAccountUpdateFormResponse)
            const localVarPath = `/api/account-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tobAccountUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * ToBアカウントパスワード再設定トークンを登録する
         * @summary ToBアカウントパスワード再設定トークン登録
         * @param {PasswordResetTokenFormResponse} passwordResetTokenFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetToken(passwordResetTokenFormResponse: PasswordResetTokenFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetTokenOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetToken(passwordResetTokenFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ToBアカウントパスワード再設定トークンを登録する
         * @summary ToBアカウントパスワード再設定
         * @param {PasswordResetUpdateFormResponse} passwordResetUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetUpdate(passwordResetUpdateFormResponse: PasswordResetUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetUpdateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetUpdate(passwordResetUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウント情報を作成する
         * @summary アカウント情報作成
         * @param {TobAccountCreateFormResponse} tobAccountCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountCreate(tobAccountCreateFormResponse: TobAccountCreateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TobAccountCreateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountCreate(tobAccountCreateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントを削除する
         * @summary アカウント削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウント情報を取得する
         * @summary アカウント情報取得
         * @param {string} [accountUpdteId] 
         * @param {string} [accountUpdteClientOrgShopId] 
         * @param {string} [allClient] 
         * @param {string} [accountPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountInfo(accountUpdteId?: string, accountUpdteClientOrgShopId?: string, allClient?: string, accountPage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TobAccountInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountInfo(accountUpdteId, accountUpdteClientOrgShopId, allClient, accountPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウント一覧を取得する
         * @summary アカウント一覧
         * @param {string} [allClient] 
         * @param {string} [accountListPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountList(allClient?: string, accountListPage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TobAccountListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountList(allClient, accountListPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウント情報を更新した後、更新後リストを返す
         * @summary アカウント情報更新
         * @param {TobAccountListUpdateFormResponse} tobAccountListUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountListUpdate(tobAccountListUpdateFormResponse: TobAccountListUpdateFormResponse, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TobAccountListUpdateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountListUpdate(tobAccountListUpdateFormResponse, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TobAccountクライアント・組織・店舗リストを取得する
         * @summary TobAccountクライアント・組織・店舗リスト取得
         * @param {string} [accountid] 
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountRadioList(accountid?: string, id?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TobAccountSubListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountRadioList(accountid, id, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * サブ所属リストを取得する
         * @summary サブ所属リスト
         * @param {string} [accountid] 
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountSubListOutput(accountid?: string, id?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TobAccountSubListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountSubListOutput(accountid, id, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントのサブ所属を設定追加
         * @summary アカウントサブ所属追加
         * @param {TobAccountSubRefAddFormResponse} tobAccountSubRefAddFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountSubRefAdd(tobAccountSubRefAddFormResponse: TobAccountSubRefAddFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TobAccountSubRefAddOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountSubRefAdd(tobAccountSubRefAddFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントのサブ所属を削除
         * @summary アカウントサブ所属削除
         * @param {string} [allClient] 
         * @param {string} [subRefId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountSubRefDel(allClient?: string, subRefId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResultOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountSubRefDel(allClient, subRefId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントのサブ所属リストを取得する
         * @summary アカウントのサブ所属リスト
         * @param {string} [accountid] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountSubRefList(accountid?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TobAccountSubRefListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountSubRefList(accountid, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウント情報を更新する
         * @summary アカウント情報更新
         * @param {TobAccountUpdateFormResponse} tobAccountUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountUpdate(tobAccountUpdateFormResponse: TobAccountUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TobAccountUpdateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountUpdate(tobAccountUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * ToBアカウントパスワード再設定トークンを登録する
         * @summary ToBアカウントパスワード再設定トークン登録
         * @param {PasswordResetTokenFormResponse} passwordResetTokenFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetToken(passwordResetTokenFormResponse: PasswordResetTokenFormResponse, options?: any): AxiosPromise<PasswordResetTokenOutputResponse> {
            return localVarFp.passwordResetToken(passwordResetTokenFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ToBアカウントパスワード再設定トークンを登録する
         * @summary ToBアカウントパスワード再設定
         * @param {PasswordResetUpdateFormResponse} passwordResetUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetUpdate(passwordResetUpdateFormResponse: PasswordResetUpdateFormResponse, options?: any): AxiosPromise<PasswordResetUpdateOutputResponse> {
            return localVarFp.passwordResetUpdate(passwordResetUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウント情報を作成する
         * @summary アカウント情報作成
         * @param {TobAccountCreateFormResponse} tobAccountCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountCreate(tobAccountCreateFormResponse: TobAccountCreateFormResponse, options?: any): AxiosPromise<TobAccountCreateOutputResponse> {
            return localVarFp.tobAccountCreate(tobAccountCreateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントを削除する
         * @summary アカウント削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountDelete(id: number, options?: any): AxiosPromise<ResultOutputResponse> {
            return localVarFp.tobAccountDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウント情報を取得する
         * @summary アカウント情報取得
         * @param {string} [accountUpdteId] 
         * @param {string} [accountUpdteClientOrgShopId] 
         * @param {string} [allClient] 
         * @param {string} [accountPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountInfo(accountUpdteId?: string, accountUpdteClientOrgShopId?: string, allClient?: string, accountPage?: string, options?: any): AxiosPromise<TobAccountInfoOutputResponse> {
            return localVarFp.tobAccountInfo(accountUpdteId, accountUpdteClientOrgShopId, allClient, accountPage, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウント一覧を取得する
         * @summary アカウント一覧
         * @param {string} [allClient] 
         * @param {string} [accountListPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountList(allClient?: string, accountListPage?: string, options?: any): AxiosPromise<TobAccountListOutputResponse> {
            return localVarFp.tobAccountList(allClient, accountListPage, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウント情報を更新した後、更新後リストを返す
         * @summary アカウント情報更新
         * @param {TobAccountListUpdateFormResponse} tobAccountListUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountListUpdate(tobAccountListUpdateFormResponse: TobAccountListUpdateFormResponse, allClient?: string, options?: any): AxiosPromise<TobAccountListUpdateOutputResponse> {
            return localVarFp.tobAccountListUpdate(tobAccountListUpdateFormResponse, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * TobAccountクライアント・組織・店舗リストを取得する
         * @summary TobAccountクライアント・組織・店舗リスト取得
         * @param {string} [accountid] 
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountRadioList(accountid?: string, id?: string, allClient?: string, options?: any): AxiosPromise<TobAccountSubListOutputResponse> {
            return localVarFp.tobAccountRadioList(accountid, id, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * サブ所属リストを取得する
         * @summary サブ所属リスト
         * @param {string} [accountid] 
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountSubListOutput(accountid?: string, id?: string, allClient?: string, options?: any): AxiosPromise<TobAccountSubListOutputResponse> {
            return localVarFp.tobAccountSubListOutput(accountid, id, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントのサブ所属を設定追加
         * @summary アカウントサブ所属追加
         * @param {TobAccountSubRefAddFormResponse} tobAccountSubRefAddFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountSubRefAdd(tobAccountSubRefAddFormResponse: TobAccountSubRefAddFormResponse, options?: any): AxiosPromise<Array<TobAccountSubRefAddOutputResponse>> {
            return localVarFp.tobAccountSubRefAdd(tobAccountSubRefAddFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントのサブ所属を削除
         * @summary アカウントサブ所属削除
         * @param {string} [allClient] 
         * @param {string} [subRefId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountSubRefDel(allClient?: string, subRefId?: string, options?: any): AxiosPromise<Array<ResultOutputResponse>> {
            return localVarFp.tobAccountSubRefDel(allClient, subRefId, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントのサブ所属リストを取得する
         * @summary アカウントのサブ所属リスト
         * @param {string} [accountid] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountSubRefList(accountid?: string, allClient?: string, options?: any): AxiosPromise<TobAccountSubRefListOutputResponse> {
            return localVarFp.tobAccountSubRefList(accountid, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウント情報を更新する
         * @summary アカウント情報更新
         * @param {TobAccountUpdateFormResponse} tobAccountUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountUpdate(tobAccountUpdateFormResponse: TobAccountUpdateFormResponse, options?: any): AxiosPromise<TobAccountUpdateOutputResponse> {
            return localVarFp.tobAccountUpdate(tobAccountUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * ToBアカウントパスワード再設定トークンを登録する
     * @summary ToBアカウントパスワード再設定トークン登録
     * @param {PasswordResetTokenFormResponse} passwordResetTokenFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public passwordResetToken(passwordResetTokenFormResponse: PasswordResetTokenFormResponse, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).passwordResetToken(passwordResetTokenFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ToBアカウントパスワード再設定トークンを登録する
     * @summary ToBアカウントパスワード再設定
     * @param {PasswordResetUpdateFormResponse} passwordResetUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public passwordResetUpdate(passwordResetUpdateFormResponse: PasswordResetUpdateFormResponse, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).passwordResetUpdate(passwordResetUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウント情報を作成する
     * @summary アカウント情報作成
     * @param {TobAccountCreateFormResponse} tobAccountCreateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tobAccountCreate(tobAccountCreateFormResponse: TobAccountCreateFormResponse, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tobAccountCreate(tobAccountCreateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントを削除する
     * @summary アカウント削除
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tobAccountDelete(id: number, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tobAccountDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウント情報を取得する
     * @summary アカウント情報取得
     * @param {string} [accountUpdteId] 
     * @param {string} [accountUpdteClientOrgShopId] 
     * @param {string} [allClient] 
     * @param {string} [accountPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tobAccountInfo(accountUpdteId?: string, accountUpdteClientOrgShopId?: string, allClient?: string, accountPage?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tobAccountInfo(accountUpdteId, accountUpdteClientOrgShopId, allClient, accountPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウント一覧を取得する
     * @summary アカウント一覧
     * @param {string} [allClient] 
     * @param {string} [accountListPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tobAccountList(allClient?: string, accountListPage?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tobAccountList(allClient, accountListPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウント情報を更新した後、更新後リストを返す
     * @summary アカウント情報更新
     * @param {TobAccountListUpdateFormResponse} tobAccountListUpdateFormResponse 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tobAccountListUpdate(tobAccountListUpdateFormResponse: TobAccountListUpdateFormResponse, allClient?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tobAccountListUpdate(tobAccountListUpdateFormResponse, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TobAccountクライアント・組織・店舗リストを取得する
     * @summary TobAccountクライアント・組織・店舗リスト取得
     * @param {string} [accountid] 
     * @param {string} [id] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tobAccountRadioList(accountid?: string, id?: string, allClient?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tobAccountRadioList(accountid, id, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * サブ所属リストを取得する
     * @summary サブ所属リスト
     * @param {string} [accountid] 
     * @param {string} [id] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tobAccountSubListOutput(accountid?: string, id?: string, allClient?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tobAccountSubListOutput(accountid, id, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントのサブ所属を設定追加
     * @summary アカウントサブ所属追加
     * @param {TobAccountSubRefAddFormResponse} tobAccountSubRefAddFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tobAccountSubRefAdd(tobAccountSubRefAddFormResponse: TobAccountSubRefAddFormResponse, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tobAccountSubRefAdd(tobAccountSubRefAddFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントのサブ所属を削除
     * @summary アカウントサブ所属削除
     * @param {string} [allClient] 
     * @param {string} [subRefId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tobAccountSubRefDel(allClient?: string, subRefId?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tobAccountSubRefDel(allClient, subRefId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントのサブ所属リストを取得する
     * @summary アカウントのサブ所属リスト
     * @param {string} [accountid] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tobAccountSubRefList(accountid?: string, allClient?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tobAccountSubRefList(accountid, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウント情報を更新する
     * @summary アカウント情報更新
     * @param {TobAccountUpdateFormResponse} tobAccountUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tobAccountUpdate(tobAccountUpdateFormResponse: TobAccountUpdateFormResponse, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tobAccountUpdate(tobAccountUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}

