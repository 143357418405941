import React, { memo, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { AccountApi, TobAccountCreateFormResponse, TobAccountInfoOutputRowDataResponse } from '../../../api-client';
import scss from '../../../scss/organisms/registerUser.module.scss';
import { SubmitLoadingButton } from '../../atoms/Button/UnitButton';
import { PasswordForm, TextForm } from './TextForm';
import { schema } from '../../../services/registerFormValidation';
import { RadioGroup } from '../../molecules/Form/RadioGroup';
import { BelongFormSelectGroup } from '../../molecules/Form/BelongFormSelectGroup';
import { AuthCheckFormGroup } from '../../molecules/Form/CheckGroup';
import { MailSelectGroup } from '../../molecules/Form/MailSelectGroup';
import { Url } from '../../../constants/Url';
import { OtherBelongFormSelectGroup } from '../../molecules/Form/OtherBelongFormSelectGroup';

type Props = {
  account: TobAccountInfoOutputRowDataResponse;
};

export const EditAccount: React.FC<Props> = memo(({ account }) => {
  const [error, setError] = useState<any | undefined>();
  const history = useHistory();
  const noChange = true;
  const {
    handleSubmit,
    getValues,
    register,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<TobAccountCreateFormResponse>({
    resolver: yupResolver(schema),
  });

  const [defaultValues, setDefaultValues] = useState<TobAccountCreateFormResponse>({
    belong: account.belongCode.toString(),
    clientAdminFlg: account.clientAdminFlg,
    confirmePassword: '',
    giftViewFlg: account.giftViewFlg,
    goalSetFlg: account.goalSetFlg,
    initPassword: false,
    mailSendFlg: account.mailSendFlg,
    mailaddress: account.mailaddress,
    name: account.name,
    password: '',
    roiAdminFlg: account.roiAdminFlg,
    userEditFlg: account.userEditFlg,
    viewAllFlg: account.viewAllFlg,
  });

  useEffect(() => {
    reset(defaultValues); // useFormの値を上書き
  }, [defaultValues]);

  const onSubmit = async (data: TobAccountCreateFormResponse) => {
    setError(undefined);
    const api = new AccountApi();
    await api
      .tobAccountUpdate({
        ...data,
        belongCode: data.belong,
        id: account.id.toString(),
        tobAccountClientOrgShopId: account.tobAccountClientOrgShopId.toString(),
        password: data.password === '' ? undefined : data.password,
        confirmePassword: data.confirmePassword === '' ? undefined : data.confirmePassword,
        initPassword: false,
      })
      .then((res) => {
        console.log(res);
        if (res.data.result === undefined) {
          handleTransition(); // TODO なぜか戻り値が入っていない場合の応急処置
        } else if (res.data.result) {
          handleTransition();
        } else {
          setError(res.data.error);
        }
      })
      .catch((e) => {
        console.error(e.message);
      });
  };

  const handleTransition = () => {
    history.push(Url.USER_LIST);
  };

  return (
    <Card className={scss.card}>
      アカウントを編集します。
      <br />
      パスワードに使用できる文字は英数字(A-Z、a-z、0-9)です。また必ず英字、数字それぞれ1文字以上含んでいる必要があります。
      <br />
      パスワードを変更しない場合は省略可能です。
      <br />
      権限を設定すると、対象の権限以下を参照することができるようになります。
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextForm register={register} errors={errors} error={error} submitting={isSubmitting} />
        <PasswordForm register={register} errors={errors} error={error} submitting={isSubmitting} />
        <div className="fw-bold mt-2">所属</div>
        <BelongFormSelectGroup
          register={register}
          setValue={setValue}
          defaultValues={account.belong}
          submitting={isSubmitting}
          noChange={noChange}
          error={error}
        />
        <div className="fw-bold mt-2">権限</div>
        <AuthCheckFormGroup
          register={register}
          getValues={getValues}
          setValue={setValue}
          submitting={isSubmitting}
          roiAdminDisplayFlg={false}
          noChange={noChange}
        />
        <MailSelectGroup />
        <SubmitLoadingButton label="変更" submitting={isSubmitting} />
      </form>
    </Card>
  );
});
