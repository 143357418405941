import React, { Suspense, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import scss from '../../scss/templates/layout.module.scss';
import { HeaderLayout } from './HeaderLayout';
import { SideBarLayout } from './SideBarLayout';
import { MainLayout } from '../organisms/MainLayout';
import InquiryModal from '../organisms/InquiryModal';
import { Head } from '../organisms/Head';
import { LoadingLayout } from './LoadingLayout';
import { sidebarState } from '../../states/atom/CommonPageState';
import { SidebarOverlay } from './SidebarOverlay';
import { NewsApi, NewsInfoOutputResponse } from '../../api-client';
import { ConfirmModal } from '../organisms/ConfirmModal';

type Props = {
  title: string;
};

export const Layout: React.FC<Props> = ({ title, children }) => {
  const { pathname } = useLocation();
  const [sidebar, setSidebar] = useRecoilState(sidebarState);
  // const isSpScreen: boolean = useMediaQuery({ query: '(max-width: 767px)' });
  const isTabletScreen: boolean = useMediaQuery({ query: '(max-width: 991px)' });

  const [badge, setBadge] = useState(false);

  // SPサイズになったらサイドバー閉じる
  useEffect(() => {
    setSidebar(!isTabletScreen);
  }, [isTabletScreen]);

  useEffect(() => {
    // SP画面で画面遷移したらサイドバー閉じる
    handleClick();
    // お知らせバッジを表示するか確認
    // handleSearchNews();
  }, [pathname]);

  // SP画面でサイドバー開いているときに縦スクロールさせない
  useEffect(() => {
    if (isTabletScreen && sidebar) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [sidebar]);

  // サイドバー以外をタップしたらサイドバー閉じる
  const handleClick = () => {
    if (isTabletScreen) {
      setSidebar(false);
    }
  };

  // const handleSearchNews = async () => {
  //   const api = new NewsApi();
  //   return api.newsInfo('3', true).then((res: AxiosResponse<NewsInfoOutputResponse>) => {
  //     setBadge(res.data.list.length > 0);
  //   });
  // };

  return (
    <>
      <Head title={title} />
      <Container fluid className={`${scss.wrapper_header}`}>
        <Row>
          <HeaderLayout badge={badge} />
        </Row>
      </Container>
      <Container fluid className={`${scss.wrapper_header2}`}>
        <Row className={`${scss.wrapper_row}`}>
          {/** d-noneしてあげないと、見えてないサイドバーのDOMが邪魔してスクロールできなくなる */}
          <Col
            className={`${scss.wrapper_col1} ${
              sidebar ? scss.wrapper_col1_sidebar_open : `${scss.wrapper_col1_sidebar_close} d-none d-lg-block`
            }`}
          >
            <SideBarLayout />
          </Col>
          <Col
            className={`${scss.wrapper_col2} ${
              sidebar ? scss.wrapper_col2_sidebar_open : scss.wrapper_col2_sidebar_close
            }`}
          >
            <MainLayout title={title}>{children}</MainLayout>
            {/** SP画面でサイドバー開いたら、半透明オーバーレイする */}
            {sidebar && isTabletScreen && <SidebarOverlay handleClick={handleClick} />}
          </Col>
          <InquiryModal />
        </Row>
      </Container>
    </>
  );
};
