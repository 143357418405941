export const SITE_TITLE = '検証';
export const SITE_DESCRIPTION = '検証アプリ';

export const SUCCESS = '成功';
export const ERROR = '失敗';
export const CLEAR = 'クリア';

export const YEAR = '年';
export const MONTH = '月';

// Service Mode
export const SERVICE_MODE_NORMAL = 0;
export const SERVICE_MODE_MAINTENANCE = 99;

// Code
export const CODE = 'code';
export const CODE_NODATA = 'nodata';

// Token
export const ACCESS_TOKEN_BEARER = 'Bearer ';
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';

// select or stack
export const SELECT_STACK = 'selectStack';
export const NEW_CLOUD = 'newCloud';
export const CATEGORY_ID = 'categoryId';

// 組織Select文字
export const ORGANIZATION_COMMA = 'H,';

export const LOGOUT_DELETE_COOKIE = [ACCESS_TOKEN, REFRESH_TOKEN, SELECT_STACK, NEW_CLOUD, CATEGORY_ID];
