import React, { Dispatch, memo, SetStateAction, useEffect, useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Form } from 'react-bootstrap';
import { TobAccountCreateFormResponse, TobAccountUpdateFormResponse } from '../../../api-client';
import {
  belongRadioSelectedFlgState,
  mainBelongEditFlgState,
  mainBelongListState,
  mainSelectedAuthState,
  mainSelectedBelongState,
  subEditFlgState,
} from '../../../states/atom/RegisterFormState';
import { ToolTip } from '../../atoms/Tooltip';
import { ORGANIZATION_COMMA } from '../../../Constants';
import { useUrlParams } from '../../../hooks/useUrlParams';
import { tobAccountBelongSelectSelector } from '../../../states/selector/TobAccountBelongSelectSelector';
import { useParamClientAll } from '../../../hooks/useParamClientAll';

type Props = {
  register: UseFormRegister<TobAccountCreateFormResponse>;
  setValue: UseFormSetValue<TobAccountCreateFormResponse>;
  defaultValues?: string;
  submitting: boolean;
  noChange?: boolean;
  subSelectReset?: () => void;
  mainSelectResetFlg?: boolean;
  setMainSelectResetFlg?: Dispatch<SetStateAction<boolean>>;
  error: { [key: string]: string } | undefined;
};
export const BelongFormSelectGroup: React.FC<Props> = ({
  register,
  setValue,
  defaultValues,
  submitting,
  noChange = false,
  subSelectReset,
  mainSelectResetFlg,
  setMainSelectResetFlg,
  error,
}) => {
  const [refresh, setRefresh] = useState<boolean>(false);
  const { pathname } = useLocation();
  const params = useUrlParams();
  const paramAllClient = useParamClientAll();
  const numAccountId = params.accountUpdateId === '' ? '' : params.accountUpdateId;
  const strAccountId: string | undefined = params.accountUpdateId === '' ? '' : `${params.accountUpdateId}`;
  // メインで選択した権限
  const mainAuth = useRecoilValue(mainSelectedAuthState(strAccountId));
  // 初回またはデータの変更が合った時に取得データを保存するよう
  // メインのセレクトボックスのデータ（初回はLoading走るが二回目以降はatomに保存したものを返す）
  const selectList = useRecoilValue(tobAccountBelongSelectSelector(`${numAccountId}:${paramAllClient}`));
  // 選択した所属をatomで管理（サブ所属で使用）
  const [, setSelect] = useRecoilState(mainSelectedBelongState(strAccountId));
  // メイン所属変更したFlg
  const [mainEditFlg, setMainEditFlg] = useRecoilState(mainBelongEditFlgState(strAccountId));
  // サブ所属変更したFlg
  const [subEditFlg] = useRecoilState(subEditFlgState(strAccountId));
  const regexp = new RegExp(ORGANIZATION_COMMA);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelect(e.target.value);
    setMainEditFlg(true);
  };

  useEffect(() => {
    if (!mainEditFlg) {
      return;
    }
    // setBelongList(selectList);
    // const belognCode = currentUser.belongCode.split(',');
    const code = defaultValues?.split(',');
    // ラジオボタン選択
    if (mainAuth === '1' && selectList) {
      if (selectList.clientList.length > 0) {
        setSelect(selectList.clientList[0].value);
        setValue('belong', selectList.clientList[0].value);
      }
    } else if (mainAuth === '2' && selectList) {
      if (selectList.orgList.length > 0) {
        const find = selectList.orgList.find((it) => it.hierarchyId === mainAuth && !it.hierarchyFlg);
        if (find) {
          setSelect(find!.value);
          setValue('belong', find!.value);
        }
      }
    } else if (mainAuth === '3' && selectList) {
      if (selectList.shopList.length > 0) {
        setSelect(selectList.shopList[0].value);
        setValue('belong', selectList.shopList[0].value);
      }
    }
    // ユーザ更新時
    if (code !== undefined && code[0] === 'C' && selectList && defaultValues !== undefined) {
      setValue('belong', defaultValues);
      setValue('viewAllFlg', true);
      setSelect(defaultValues);
    } else if (code !== undefined && code[0] === 'O' && selectList && defaultValues !== undefined) {
      if (selectList.orgList.length > 0) {
        const find = selectList.orgList.find((it) => it.hierarchyId === mainAuth && !it.hierarchyFlg);
        if (find) {
          setValue('belong', find!.value);
          setSelect(find!.value);
        }
      }
    } else if (code !== undefined && code[0] === 'S' && selectList && defaultValues !== undefined) {
      if (selectList.shopList.length > 0) {
        setValue('belong', selectList.shopList[0].value);
        setSelect(selectList.shopList[0].value);
      }
    }
    // ユーザ登録時
    if (code !== undefined && code[0] !== 'C' && mainAuth === '1' && selectList) {
      if (selectList.clientList.length > 0) {
        setValue('belong', selectList.clientList[0].value);
        setValue('viewAllFlg', true);
        setSelect(selectList.clientList[0].value);
      }
    } else if (code !== undefined && code[0] !== 'O' && mainAuth.includes('H') && selectList) {
      if (selectList.orgList.length > 0) {
        const find = selectList.orgList.find((it) => it.hierarchyId === mainAuth && !it.hierarchyFlg);
        setValue('belong', find!.value);
        setSelect(find!.value);
      }
    } else if (code !== undefined && code[0] !== 'S' && mainAuth === '3' && selectList) {
      if (selectList.shopList.length > 0) {
        setValue('belong', selectList.shopList[0].value);
        setSelect(selectList.shopList[0].value);
      }
    }
  }, [mainAuth]);

  // 初回表示時
  useEffect(() => {
    // 初回表示時
    if (defaultValues && mainAuth === '') {
      setValue('belong', defaultValues);
      setSelect(defaultValues);
    }
  }, []);

  return (
    <ToolTip content="ユーザの権限を設定できます。所属する店舗や部署などを設定するのが一般的です。">
      <Form.Select
        {...register('belong')}
        className="mb-3 w-100 py-1"
        style={{ cursor: 'pointer' }}
        disabled={submitting || noChange || subEditFlg}
        onChange={handleChange}
        defaultValue={defaultValues}
      >
        {mainAuth === '1' && selectList && (
          <>
            <option disabled key="C">
              ---本社----------
            </option>
            {selectList.clientList.map((client, i) => (
              <option key={client.value} value={client.value}>
                {client.label}
              </option>
            ))}
          </>
        )}
        {mainAuth.match(regexp) && selectList && (
          <>
            {selectList.orgList.map((group, i) =>
              // 階層IDが同じなら表示
              // eslint-disable-next-line no-nested-ternary
              mainAuth === group.hierarchyId ? (
                // 文字列にHが含まれてる
                group.hierarchyFlg ? (
                  <option key={group.value} disabled>
                    ---{group.label}----------
                  </option>
                ) : (
                  <option key={group.value} value={group.value}>
                    {group.label}
                  </option>
                )
              ) : (
                <></>
              )
            )}
          </>
        )}
        {mainAuth === '3' && selectList && (
          <>
            <option disabled key="S">
              ---店舗----------
            </option>
            {selectList.shopList.map((shop, i) => (
              <option key={shop.value} value={shop.value}>
                {shop.label}
              </option>
            ))}
          </>
        )}
      </Form.Select>
      <p style={{ color: '#EB5757' }}>{error?.belong}</p>
    </ToolTip>
  );
};

type UpdateProps = {
  register: UseFormRegister<TobAccountUpdateFormResponse>;
  setValue: UseFormSetValue<TobAccountUpdateFormResponse>;
};

export const UpdateBelongSelectGroup: React.FC<UpdateProps> = memo(({ register, setValue }) => {
  const { pathname } = useLocation();
  const params = useUrlParams();
  const path = `${pathname}_${params.accountUpdateId}_${params.accountUpdateClientOrgShopId}`;
  const accountId = params.accountUpdateId === '' ? '' : `:${params.accountUpdateId}`;
  const numAccountId = params.accountUpdateId === '' ? '' : params.accountUpdateId;
  const paramAllClient = useParamClientAll();
  // メインで選択した権限
  const mainAuth = useRecoilValue(mainSelectedAuthState(numAccountId));
  // 初回またはデータの変更が合った時に取得データを保存するよう
  const setBelongList = useSetRecoilState(mainBelongListState(numAccountId));
  // メインのセレクトボックスのデータ（初回はLoading走るが二回目以降はatomに保存したものを返す）
  const selectList = useRecoilValue(tobAccountBelongSelectSelector(`${numAccountId}:${paramAllClient}`));
  // 選択した所属をatomで管理（サブ所属で使用）
  const setSelect = useSetRecoilState(mainSelectedBelongState(numAccountId));

  useEffect(() => {
    setBelongList(selectList);

    if (mainAuth === '1' && selectList) {
      setValue('belong', selectList.clientList[0].value);
      setValue('viewAllFlg', true);
      setSelect(selectList.clientList[0].value);
    } else if (mainAuth === '2' && selectList) {
      setValue('belong', selectList.orgList[0].value);
      setValue('viewAllFlg', false);
      setSelect(selectList.orgList[0].value);
    } else if (mainAuth === '3' && selectList) {
      setValue('belong', selectList.shopList[0].value);
      setValue('viewAllFlg', false);
      setSelect(selectList.shopList[0].value);
    }
  }, [mainAuth]);

  return (
    <ToolTip content="ユーザの権限を設定できます。所属する店舗や部署などを設定するのが一般的です。">
      <Form.Select {...register('belong')} className="mb-3 w-100 py-1" onChange={(e) => setSelect(e.target.value)}>
        {mainAuth === '1' && selectList && (
          <>
            <option disabled>---本社----------</option>
            {selectList.clientList.map((client, i) => (
              <option key={i} value={client.value}>
                {client.label}
              </option>
            ))}
          </>
        )}
        {mainAuth === '2' && selectList && (
          <>
            {selectList.orgList.map((group, i) => {
              // 文字列にHが含まれてる
              if (group.value.includes('H')) {
                <option disabled>---{group.label}----------</option>;
              } else {
                <option key={i} value={group.value}>
                  {group.label}
                </option>;
              }
            })}
          </>
        )}
        {mainAuth === '3' && selectList && (
          <>
            <option disabled>---店舗----------</option>
            {selectList.shopList.map((shop, i) => (
              <option key={i} value={shop.value}>
                {shop.label}
              </option>
            ))}
          </>
        )}
      </Form.Select>
      <p style={{ color: '#EB5757' }}>(error.belong?.message)</p>
    </ToolTip>
  );
});
